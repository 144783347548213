<!-- User profile -->


<div class="user-profile" style="overflow-y: hidden !important;">
    <div class="col-12 text-center no-border">
        <img class="text-center" src="assets/images/logo.png" class="img-fluid" alt="homepage" height="10" />
    </div>
    <!-- User profile image -->
    <!-- User profile text-->
    <div class="profile-text mt-2">
        <h5> {{nombre}}</h5>
        <a (click)="cerrarSesion()" style="cursor: pointer;">
            <i class="fa fa-power-off"></i> Cerrar Sesion</a>
    </div>
</div>
<!-- End User profile text-->
<hr>
<!-- Sidebar navigation-->

<nav class="sidebar-nav" style="overflow-y: auto !important">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div>
            <a [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                (click)="addExpandClass(sidebarnavItem.title,sidebarnavItem.path )">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}
                    <span *ngIf="sidebarnavItem.label != '' "
                        [ngClass]="[sidebarnavItem.labelClass]">{{sidebarnavItem.label}}</span>
                </span>
            </a>
            <!-- Second level menu -->
            <ul class="collapse" *ngIf="sidebarnavItem.submenu.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                    [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
                        [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;"
                        (click)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        {{sidebarnavSubItem.title}}
                    </a>
                    <!-- Third level menu -->
                    <ul class="collapse" *ngIf="sidebarnavSubItem.submenu.length > 0"
                        [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu" routerLinkActive="active"
                            [ngClass]="[sidebarnavSubsubItem.class]">
                            <a [routerLink]="[sidebarnavSubsubItem.path]" *ngIf="!sidebarnavSubsubItem.extralink;"
                                [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'router-link-active'">
                                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                {{sidebarnavSubsubItem.title}}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>
<!-- End Sidebar navigation -->