<header class="topbar">
    <nav class="d-flex top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse" height="10">

            <div class="col-1">
                <ul class="navbar-nav  mt-md-0">
                    <!-- This is  -->
                    <li (click)="toggleSidebar.emit()" class="nav-item m-l-10">
                        <a class="nav-link sidebartoggler waves-effect waves-dark" href="javascript:void(0)"><i
                                class="ti-menu"></i></a>
                    </li>

                </ul>

            </div>

            <div class="col-11 text-center">
                <a href="/#/alertas">
                    <img src="assets/images/banidex.png" alt="homepage" height="56" />

                </a>

            </div>
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->





            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
        </div>
    </nav>
</header>