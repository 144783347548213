<div #modal class="modal-lg">
    <div class="modal-header">
        <h4>Fotos Mantenimiento #{{id_mantenimiento}}</h4>
            <div class="close">
                <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
                    <span aria-hidden=true>&times;</span>
                  </button>
            </div>


    </div>
    <div class="container-fluid">
        <div class=" row justify-content-center">
            <div class="col-md-12 col-12 ">
                <div class="card mt-3  bg-white ">

                    <div class="row">
                        <div *ngIf="fotosLoading" class="col-md-12 d-flex justify-content-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>

                        <div *ngFor="let item of urls" class="col-md-4 col-6">
                            <div class="imageContainer">
                                <img (click)="open(item)" id="myImg" class="img-fluid" [src]="item"
                                    alt="mantenimiento {{id_mantenimiento}}">

                            </div>


                        </div>
                        <p *ngIf="!fotosLoading && urls  && (urls.length==0)">No existen fotos</p>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<ng-template #myModal let-modal>



    <!-- The Close Button -->
    <span class="close2" (click)="modal.dismiss('Cross click')">&times;</span>

    <img class="modal-content2 p-0" style="background-color: black !important;" id="img01">



    <!-- Modal Caption (Image Text) -->

</ng-template>