import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { RequestService } from '../../services/request.service';
import { FullComponent } from '../../layouts/full/full.component';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  nombre: string = '';
  showMenu = '';
  showSubMenu = '';
  public innerWidth: any;

  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any, path: string) {
    this.innerWidth = window.innerWidth;
    if (path) {
      if (this.innerWidth < 1170) {
        this.full.showMinisidebar = !this.full.showMinisidebar
      }
    }
    if (element === this.showMenu) {
      this.showMenu = '0';

    } else {
      this.showMenu = element;
      console.log(element)


    }
  }
  addActiveClass(element: any) {
    this.innerWidth = window.innerWidth;

    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
      console.log(this.innerWidth)
      if (this.innerWidth < 1170) {
        this.full.showMinisidebar = !this.full.showMinisidebar
      }
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private requestServ: RequestService,
    public full: FullComponent
  ) { }
  // End open close
  ngOnInit() {
    this.nombre = this.requestServ.master.nombre;
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
  }
  cerrarSesion(): void {
    this.requestServ.cerrarSesion();
  }
}
