import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { ClientesPanelComponent } from './clientes/clientes-panel/clientes-panel.component';
import { LoginGuard } from './guards/login.guard';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }

    ]
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [


      {
        path: 'component',
        loadChildren: () => import('./component/component.module').then(m => m.ComponentsModule)
      },
      {
        path: 'administradores',
        loadChildren: () => import('./administradores/administradores.module').then(m => m.AdministradoresModule)
        , data: { title: 'administradores' }
      },
      {
        path: 'alertas',
        loadChildren: () => import('./alertas/alertas.module').then(m => m.AlertasModule)
        , data: { title: 'alertas' }

      },
      {
        path: 'clientes',
        loadChildren: () => import('./clientes/clientes.module').then(m => m.ClientesModule)
        , data: { title: 'clientes' }

      },
      {
        path: 'mantenimientos',
        loadChildren: () => import('./mantenimientos/mantenimientos.module').then(m => m.MantenimientosModule)
        , data: { title: 'mantenimientos' }

      },
      {
        path: 'reportes',
        loadChildren: () => import('./reportes/reportes.module').then(m => m.ReportesModule)
        , data: { title: 'reportes' }

      }

    ]
  },
  {
    path: '**',
    redirectTo: 'login'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled"
  }), NgbModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
