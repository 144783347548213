<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="
    {
        'defaultdark': color == 'defaultdark',
        'greendark': color == 'greendark',
        'reddark': color == 'reddark',
        'bluedark': color == 'bluedark',
        'purpledark': color == 'purpledark',
        'megnadark': color == 'megnadark',
        'default': color == 'default',
        'green': color == 'green',
        'red': color == 'red',
        'blue': color == 'blue',
        'purple': color == 'purple',
        'megna': color == 'megna', 
        'mini-sidebar': showMinisidebar,
        'dark-theme': showDarktheme,
        'rtl-theme': showRtl
    }
">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- ============================================================== -->


    <app-navigation class="w-100" (toggleSidebar)="toggleSidebar()"></app-navigation>
    <!-- ============================================================== -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <aside class="left-sidebar">
        <!-- Sidebar scroll-->
        <div class="scroll-sidebar" [perfectScrollbar]="config">
            <app-sidebar></app-sidebar>
        </div>
        <!-- End Sidebar scroll-->
    </aside>
    <!-- ============================================================== -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Page wrapper  -->
    <!-- ============================================================== -->
    <div class=" page-wrapper ">


        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid " style="overflow-y: auto;">

            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <div class="mt-3" (click)="routerClick()">
                <router-outlet></router-outlet>

            </div>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- Setting Right sidebar -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- End Right sidebar -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page wrapper  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer">
        © 2020 TODOS LOS DERECHOS RESERVADOS BANIDEX S.A.
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->

</div>