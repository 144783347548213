import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  {
    path: '',
    title: 'Banidex',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '/alertas',
    title: 'Alertas',
    icon: 'mdi mdi-bell-ring',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Administradores ',
    icon: 'fa fa-shield',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/administradores/administradores-panel',
        title: 'Administradores',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }, {
        path: '/administradores/tecnicos-panel',
        title: 'Tecnicos',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/administradores/modelos-panel',
        title: 'Modelos',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '/clientes',
    title: 'Clientes',
    icon: 'mdi mdi-account',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/mantenimientos',
    title: 'Mantenimientos',
    icon: 'icon-settings',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/reportes',
    title: 'Reportes',
    icon: 'mdi mdi-note-multiple-outline',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []

  }







];
