import "rxjs/Rx";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NotifierService } from "angular-notifier";
import * as moment from "moment";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RequestService {
  loading = false;
  master: any;
  public nitems: number;
  public innerHeight: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private notifier: NotifierService
  ) {
    this.innerHeight = window.innerHeight;
    console.log(innerHeight);
    if (this.innerHeight < 746) {
      this.nitems = 6;
    } else if (this.innerHeight < 927) {
      this.nitems = 8;
    } else {
      this.nitems = 12;
    }

    moment.locale("es");
    this.cargarStorage();
  }
  playAudio() {
    let audio = new Audio();
    audio.src = "../../assets/audio/bell.wav";
    audio.load();
    audio.play();
    audio.muted = false;
  }
  showAlert(message: string, type: any) {
    this.notifier.notify(type, message);
  }
  cargarStorage() {
    if (localStorage.banidex) {
      this.master = JSON.parse(localStorage.banidex);
    }
  }
  guardarStorage(data: any) {
    this.master = data;
    localStorage.setItem("banidex", JSON.stringify(data));
  }
  tokenIsValid(status: number) {
    if (status === 401) {
      this.cerrarSesion();
      return false;
    }
    return true;
  }
  cerrarSesion() {
    console.log("entro");
    this.master = null;
    localStorage.removeItem("banidex");
    this.router.navigateByUrl("/login");
  }
  login(data: any) {
    this.loading = true;
    return new Promise((resolve) => {
      this.http.post(`${environment.apiUrl}/auth`, data).subscribe(
        (response: any) => {
          this.loading = false;
          const user = { ...response.data, apiKey: response.data.token };
          this.guardarStorage(user);
          resolve([true, null]);
        },
        (error: any) => {
          this.loading = false;
          resolve([false, error.error.data]);
        }
      );
    });
  }
  getClientes(data: string) {
    this.loading = true;
    if (!this.master.apiKey) {
      // throw new Error("Error");
      this.loading = false;
      this.router.navigateByUrl("/login");
    } else {
      const headers = new HttpHeaders({
        "access-token": this.master.apiKey,
      });
      let params = new HttpParams();
      params = params.append("filtro", data);
      return new Promise((resolve) => {
        this.http
          .get(`${environment.apiUrl}/cliente`, { params, headers })
          .subscribe(
            (response: any) => {
              //ubicar headers
              this.loading = false;
              resolve([true, response.data]);
            },
            (error: any) => {
              this.loading = false;
              if (this.tokenIsValid(error.status)) {
                this.showAlert(error.error.data, "error");
                this.router.navigateByUrl("/login");
              }
              resolve([false]);
            }
          );
      });
    }
  }
  async getClientePorId(id: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/cliente/find/${id}`, { headers })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data[0]]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            // if(this.tokenIsValid(error.status)){

            // }
            resolve([false]);
          }
        );
    });
  }
  async getAdministradores() {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http.get(`${environment.apiUrl}/usuario`, { headers }).subscribe(
        (response: any) => {
          this.loading = false;
          resolve([true, response.data]);
        },
        (error: any) => {
          this.loading = false;
          // if(!this.tokenIsValid(error.status)){
          //   this.showAlert(error.error.data,'error');
          // }
          resolve([false]);
        }
      );
    });
  }

  async desactivarCliente(idCliente, isActivo = false) {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http
        .put(
          `${environment.apiUrl}/cliente/${idCliente}/estado`,
          { isActivo },
          { headers }
        )
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            this.loading = false;
            // if(!this.tokenIsValid(error.status)){
            //   this.showAlert(error.error.data,'error');
            // }
            resolve([false]);
          }
        );
    });
  }
  async createAdministrador(data: any) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .post(`${environment.apiUrl}/usuario/create`, data, { headers })
        .subscribe(
          (response: any) => {
            this.showAlert(response.data, "success");
            resolve(true);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }

  async editAdministrador(id: any, body: any) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .put(`${environment.apiUrl}/usuario/modify/${id}`, body, { headers })
        .subscribe(
          (response: any) => {
            this.showAlert(response.data, "success");
            resolve(true);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }

  async deleteAdministrador(id: string) {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http
        .delete(`${environment.apiUrl}/usuario/delete/${id}`, { headers })
        .subscribe(
          (response: any) => {
            this.loading = false;
            this.showAlert(response.data, "success");
            resolve([true, response.data]);
          },
          (error: any) => {
            this.loading = false;
            if (!this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "eror");
            }
            resolve([false]);
          }
        );
    });
  }

  async getTecnicos() {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http.get(`${environment.apiUrl}/tecnico`, { headers }).subscribe(
        (response: any) => {
          this.loading = false;
          resolve([true, response.data]);
        },
        (error: any) => {
          this.loading = false;
          if (!this.tokenIsValid(error.status)) {
            this.showAlert(error.error.data, "error");
          }
          resolve([false]);
        }
      );
    });
  }

  async deleteTecnico(id: string) {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http
        .delete(`${environment.apiUrl}/tecnico/delete/${id}`, { headers })
        .subscribe(
          (response: any) => {
            this.loading = false;
            this.showAlert(response.data, "success");
            resolve([true, response.data]);
          },
          (error: any) => {
            this.loading = false;
            if (!this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "eror");
            }
            resolve([false]);
          }
        );
    });
  }

  async editTecnico(id: any, body: any) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .put(`${environment.apiUrl}/tecnico/modify/${id}`, body, { headers })
        .subscribe(
          (response: any) => {
            this.showAlert(response.data, "success");
            resolve(true);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }

  async createTecnico(data: any) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .post(`${environment.apiUrl}/tecnico/create`, data, { headers })
        .subscribe(
          (response: any) => {
            this.showAlert(response.data, "success");
            resolve(true);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }
  async getModelos(tipo: string = null) {
    let params = new HttpParams();
    if (tipo) {
      params = params.append("tipo", tipo);
    }

    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/modelo`, { headers, params })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            this.loading = false;
            if (!this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "eror");
            }
            resolve([false]);
          }
        );
    });
  }
  async getModeloId(id_modelo: string) {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/modelo/find/${id_modelo}`, { headers })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data[0]]);
          },
          (error: any) => {
            this.loading = false;
            if (!this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve([false]);
          }
        );
    });
  }
  async getNotasPorEquipo(id_equipo: string) {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/equipo/${id_equipo}/nota`, { headers })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            this.loading = false;
            if (!this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve([false]);
          }
        );
    });
  }

  async createModelo(data: any) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .post(`${environment.apiUrl}/modelo/create`, data, { headers })
        .subscribe(
          (response: any) => {
            this.showAlert(response.data, "success");
            resolve(true);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }

  async editModelo(id: any, body: any) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .put(`${environment.apiUrl}/modelo/modify/${id}`, body, { headers })
        .subscribe(
          (response: any) => {
            this.showAlert(response.data, "success");
            resolve(true);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }

  async deleteModelo(id: string) {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http
        .delete(`${environment.apiUrl}/modelo/delete/${id}`, { headers })
        .subscribe(
          (response: any) => {
            this.loading = false;
            this.showAlert(response.data, "success");
            resolve([true, response.data]);
          },
          (error: any) => {
            this.loading = false;
            if (!this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve([false]);
          }
        );
    });
  }
  async getEquiposPorCliente(idCliente: string, func: string) {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    let params = new HttpParams();
    params = params.append("func", func);
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/cliente/${idCliente}/equipo`, {
          headers,
          params,
        })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            this.loading = false;
            if (!this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve([false]);
          }
        );
    });
  }
  async getCiudades() {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http.get(`${environment.apiUrl}/ciudad`, { headers }).subscribe(
        (response: any) => {
          this.loading = false;
          resolve([true, response.data]);
        },
        (error: any) => {
          this.loading = false;
          if (!this.tokenIsValid(error.status)) {
            this.showAlert(error.error.data, "error");
          }
          resolve([false]);
        }
      );
    });
  }
  async getContactosCliente(clienteId: string) {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/cliente/${clienteId}/contactos`, {
          headers,
        })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            this.loading = false;
            if (!this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve([false]);
          }
        );
    });
  }
  async getContactosEquipo(id_equipo: string) {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    return new Promise((resolve) => {
      this.http
        .get(
          `${environment.apiUrl}/cliente/10001442/equipo/${id_equipo}/contactos`,
          { headers }
        )
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            this.loading = false;
            if (!this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve([false]);
          }
        );
    });
  }
  async crearEquipo(body: any) {
    this.loading = true;
    console.log(body);
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .post(`${environment.apiUrl}/equipo`, body, { headers })
        .subscribe(
          (response: any) => {
            console.log(body);
            this.showAlert(response.data, "success");
            resolve(true);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }
  async getComponentesXEquipo(id_equipo: string, func: string) {
    this.loading = true;
    const headers = new HttpHeaders({ "access-token": this.master.apiKey });
    let params = new HttpParams();
    params = params.append("func", func);
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/equipo/${id_equipo}/componente`, {
          headers,
          params,
        })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            this.loading = false;
            if (!this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve([false]);
          }
        );
    });
  }
  async agregarContactoXEquipo(body: any, id_equipo: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .post(`${environment.apiUrl}/equipo/${id_equipo}/contactos`, body, {
          headers,
        })
        .subscribe(
          (response: any) => {
            this.showAlert("Exito al agregar el contacto", "success");
            resolve([true, response.data]);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }
  async borrarContactoXEquipo(id_equipo: string, id_referenciado: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .delete(
          `${environment.apiUrl}/equipo/${id_equipo}/contactos/${id_referenciado}`,
          { headers }
        )
        .subscribe(
          (response: any) => {
            this.showAlert(response.data, "error");
            resolve(true);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }
  async getEquipoPorId(id: string, id_equipo: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/cliente/${id}/equipo/${id_equipo}`, {
          headers,
        })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data[0]]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            // if(this.tokenIsValid(error.status)){

            // }
            resolve([false]);
          }
        );
    });
  }
  async getEstablecimientoPorEquipo(id_equipo: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/equipo/${id_equipo}/establecimiento/`, {
          headers,
        })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            // if(this.tokenIsValid(error.status)){

            // }
            resolve([false]);
          }
        );
    });
  }
  async anadirNota(data: any, id_equipo: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .post(`${environment.apiUrl}/equipo/${id_equipo}/nota`, data, {
          headers,
        })
        .subscribe(
          (response: any) => {
            this.showAlert("Exito al crear la nota", "success");
            resolve([true, response.data]);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }
  async eliminarNota(id_equipo: string, id_nota: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .delete(`${environment.apiUrl}/equipo/${id_equipo}/nota/${id_nota}`, {
          headers,
        })
        .subscribe(
          (response: any) => {
            this.showAlert(response.data, "success");
            resolve(true);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }
  async createComponente(data: any, id_equipo: string) {
    console.log(data);
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .post(`${environment.apiUrl}/equipo/${id_equipo}/componente`, data, {
          headers,
        })
        .subscribe(
          (response: any) => {
            this.showAlert("Exito al crear la componente", "success");
            resolve([true, response.data]);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }
  async editComponente(data: any, id_equipo: string, id_componente: number) {
    console.log(data);

    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .put(
          `${environment.apiUrl}/equipo/${id_equipo}/componente/${id_componente}`,
          data,
          { headers }
        )
        .subscribe(
          (response: any) => {
            this.showAlert("Exito al editar el componente", "success");
            resolve([true, response.data]);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }
  async detalles(data: any, id_equipo: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .put(`${environment.apiUrl}/equipo/${id_equipo}/detalle/`, data, {
          headers,
        })
        .subscribe(
          (response: any) => {
            this.showAlert("Exito al cambiar detalles del equipo", "success");
            resolve([true, response.data]);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }
  async detallesUb(data: any, id_equipo: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .put(`${environment.apiUrl}/equipo/${id_equipo}/ubicacion/`, data, {
          headers,
        })
        .subscribe(
          (response: any) => {
            resolve([true, response.data]);
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }
  async agregarContactosCliente(data: any, id_cliente: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .post(`${environment.apiUrl}/cliente/${id_cliente}/contactos/`, data, {
          headers,
        })
        .subscribe(
          (response: any) => {
            resolve([true, response.data]);
            this.showAlert(response.data, "success");
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            if (this.tokenIsValid(error.status)) {
              this.showAlert(error.error.data, "error");
            }
            resolve(false);
          }
        );
    });
  }
  async historicoEquipo(id_equipo: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/equipo/${id_equipo}/mantenimiento/`, {
          headers,
        })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            // if(this.tokenIsValid(error.status)){

            // }
            resolve([false]);
          }
        );
    });
  }
  async detallesMantimiento(id_equipo: string, id_mantenimiento: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .get(
          `${environment.apiUrl}/equipo/${id_equipo}/mantenimiento/${id_mantenimiento}`,
          { headers }
        )
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            // if(this.tokenIsValid(error.status)){

            // }
            resolve([false]);
          }
        );
    });
  }
  async programarMantenimiento(id_equipo: string, data: any) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .post(`${environment.apiUrl}/equipo/${id_equipo}/mantenimiento`, data, {
          headers,
        })
        .subscribe(
          (response: any) => {
            this.loading = false;
            this.showAlert("Mantenimiento programado con exito", "success");
            resolve([true, response.data]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            // if(this.tokenIsValid(error.status)){

            // }
            resolve([false]);
          }
        );
    });
  }
  async generarGuia(id_equipo: string) {
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    this.http
      .get(`${environment.apiUrl}/equipo/${id_equipo}/componente/guia`, {
        responseType: "arraybuffer",
        headers: headers,
      })
      .subscribe(
        (response: any) => {
          this.downLoadFile(response, "application/pdf");
          this.loading = false;
        },
        (error: any) => {
          this.showAlert("No autorizado", "error");
          this.loading = false;
        }
      );
  }
  async modificarMantenimiento(
    id_equipo: string,
    data: any,
    id_mantenimiento: number
  ) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .put(
          `${environment.apiUrl}/equipo/${id_equipo}/mantenimiento/${id_mantenimiento}`,
          data,
          { headers }
        )
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            // if(this.tokenIsValid(error.status)){

            // }
            resolve([false]);
          }
        );
    });
  }
  async getAlertas(data = null) {
    this.loading = true;
    try {
      if (!this.master.apiKey) {
        throw new Error("Error");
      } else {
        const headers = new HttpHeaders({
          "access-token": this.master.apiKey,
        });
        let params = new HttpParams();
        let url;
        if (!data) {
          url = `${environment.apiUrl}/alerta`;
        } else {
          params = params.append("inicio", data.inicio);
          params = params.append("fin", data.fin);
          url = `${environment.apiUrl}/alerta/fecha`;
        }
        return new Promise((resolve) => {
          this.http.get(url, { headers, params }).subscribe(
            (response: any) => {
              this.loading = false;
              resolve([true, response.data]);
            },
            (error: any) => {
              console.log(error.status);
              this.loading = false;
              if (!this.tokenIsValid(error.status)) {
                this.router.navigateByUrl("/login");
              }
              resolve([false]);
            }
          );
        });
      }
    } catch (error) {
      this.loading = false;
      this.router.navigateByUrl("/login");
    }
  }
  async getAlertasH(data = null) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    let params = new HttpParams();
    let url;
    if (!data) {
      url = `${environment.apiUrl}/alerta/historico`;
    } else {
      params = params.append("inicio", data.inicio);
      params = params.append("fin", data.fin);
      url = `${environment.apiUrl}/alerta/historico/fecha`;
    }
    return new Promise((resolve) => {
      this.http.get(url, { headers, params }).subscribe(
        (response: any) => {
          this.loading = false;
          resolve([true, response.data]);
        },
        (error: any) => {
          console.log(error.status);
          this.loading = false;
          // if(this.tokenIsValid(error.status)){

          // }
          resolve([false]);
        }
      );
    });
  }
  async atenderAlerta(id_equipo: string, p: string, d: string, data: any) {
    if (data.nueva_fecha === "" || data.estado != "NA") delete data.nueva_fecha;
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    let params = new HttpParams();
    params = params.append("periodo", p);
    params = params.append("tiempo", d);
    return new Promise((resolve) => {
      this.http
        .post(`${environment.apiUrl}/alerta/${id_equipo}`, data, {
          headers,
          params,
        })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            // if(this.tokenIsValid(error.status)){
            // }
            resolve([false]);
          }
        );
    });
  }
  async actualizarEstado(estado: string, mantenimiento: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    let params = new HttpParams();
    params = params.append("estado", estado);
    params = params.append("mantenimiento", mantenimiento);
    return new Promise((resolve) => {
      this.http
        .post(`${environment.apiUrl}/alerta/actualizar`, null, {
          headers,
          params,
        })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            // if(this.tokenIsValid(error.status)){
            // }
            resolve([false]);
          }
        );
    });
  }
  async detallerAlerta(id_alerta: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/alerta/${id_alerta}`, { headers })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            // if(this.tokenIsValid(error.status)){
            // }
            resolve([false]);
          }
        );
    });
  }
  async actualizarAlertas() {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/alerta/prueba`, { headers })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            if (!this.tokenIsValid(error.status)) {
              this.router.navigateByUrl("/login");
            }
            resolve([false]);
          }
        );
    });
  }
  async getMantenimientos(
    cliente: string,
    estado: string,
    tecnico: string,
    inicio: string,
    fin: string
  ) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    let params = new HttpParams();
    let url = `${environment.apiUrl}/mantenimientos`;
    params = params.append("tecnico", tecnico);
    params = params.append("inicio", inicio);
    params = params.append("estado", estado);
    params = params.append("cliente", cliente);
    params = params.append("fin", fin);
    return new Promise((resolve) => {
      this.http.get(url, { headers, params }).subscribe(
        (response: any) => {
          this.loading = false;
          resolve([true, response.data]);
        },
        (error: any) => {
          console.log(error.status);
          this.loading = false;
          // if(this.tokenIsValid(error.status)){

          // }
          resolve([false]);
        }
      );
    });
  }
  async getUrls(id_mantenimiento: string) {
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    return new Promise((resolve) => {
      this.http
        .get(`${environment.apiUrl}/mantenimientos/${id_mantenimiento}/fotos`, {
          headers,
        })
        .subscribe(
          (response: any) => {
            this.loading = false;
            resolve([true, response.data]);
          },
          (error: any) => {
            console.log(error.status);
            this.loading = false;
            // if(this.tokenIsValid(error.status)){

            // }
            resolve([false]);
          }
        );
    });
  }
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
      alert("Please disable your Pop-up blocker and try again.");
    }
  }
  async getReporte(reporte: string, data: any) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    this.http
      .post(`${environment.apiUrl}/${reporte}/reporte`, data, {
        responseType: "arraybuffer",
        headers: headers,
      })
      .subscribe(
        (response: any) => {
          this.downLoadFile(response, "application/pdf");
          this.loading = false;
        },
        (error: any) => {
          this.showAlert("No autorizado", "error");
          this.loading = false;
        }
      );
  }
  async getReporteNuevosComponentes(id_mantenimiento: string) {
    this.loading = true;
    const headers = new HttpHeaders({
      "access-token": this.master.apiKey,
    });
    this.http
      .get(`${environment.apiUrl}/mantenimientos/${id_mantenimiento}/reporte`, {
        responseType: "arraybuffer",
        headers: headers,
      })
      .subscribe(
        (response: any) => {
          this.downLoadFile(response, "application/pdf");
          this.loading = false;
        },
        (error: any) => {
          this.showAlert("No autorizado", "error");
          this.loading = false;
        }
      );
  }
  async getCambiosMant(id_mantenimiento: string) {
    this.loading = true;
    try {
      if (!this.master.apiKey) {
        throw new Error("Error");
      } else {
        const headers = new HttpHeaders({
          "access-token": this.master.apiKey,
        });
        return new Promise((resolve) => {
          this.http
            .get(
              `${environment.apiUrl}/mantenimientos/${id_mantenimiento}/cambios`,
              { headers }
            )
            .subscribe(
              (response: any) => {
                this.loading = false;
                resolve([true, response.data]);
              },
              (error: any) => {
                console.log(error.status);
                this.loading = false;
                if (!this.tokenIsValid(error.status)) {
                  this.router.navigateByUrl("/login");
                }
                resolve([false]);
              }
            );
        });
      }
    } catch (error) {
      this.loading = false;
      this.router.navigateByUrl("/login");
    }
  }
  async eliminarMant(id_mantenimiento: string) {
    this.loading = true;
    try {
      if (!this.master.apiKey) {
        throw new Error("Error");
      } else {
        const headers = new HttpHeaders({
          "access-token": this.master.apiKey,
        });
        return new Promise((resolve) => {
          this.http
            .delete(
              `${environment.apiUrl}/mantenimientos/${id_mantenimiento}`,
              { headers }
            )
            .subscribe(
              (response: any) => {
                this.loading = false;
                resolve([true, response.data]);
              },
              (error: any) => {
                console.log(error.status);
                this.loading = false;
                if (!this.tokenIsValid(error.status)) {
                  this.router.navigateByUrl("/login");
                }
                resolve([false]);
              }
            );
        });
      }
    } catch (error) {
      this.loading = false;
      this.router.navigateByUrl("/login");
    }
  }
}
