import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireStorage } from "@angular/fire/storage";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { RequestService } from "../../services/request.service";

@Component({
  selector: "app-fotos-mantenimientos",
  templateUrl: "./fotos-mantenimientos.component.html",
  styleUrls: ["./fotos-mantenimientos.component.css"],
})
export class FotosMantenimientosComponent implements OnInit {
  @Input() id_mantenimiento: string;
  @ViewChild("myModal") modal: ElementRef;
  @ViewChild("img01") img: ElementRef;

  urls: any;
  fotosLoading = true;
  showModal: boolean;

  constructor(
    public requestServ: RequestService,
    private router: Router,
    public modalService: NgbModal,
    private db: AngularFireDatabase,
    private firebase: AngularFireStorage
  ) {}

  ngOnInit(): void {
    this.fotosLoading = true;
    this.getUrls(this.id_mantenimiento);
  }

  async getUrls(id_mantenimiento: string) {
    this.urls = [];
    this.fotosLoading = true;
    let storageRef = await this.firebase.storage
      .ref(`/images/mantenimientos/${id_mantenimiento}`)
      .listAll();
    for (let ref of storageRef.items) {
      await ref.getDownloadURL().then((url) => {
        this.urls.push(url);
      });
    }

    this.fotosLoading = false;
  }
  show() {
    this.showModal = true; // Show-Hide Modal Check
  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }
  open(path: string) {
    this.modalService.open(this.modal, {
      ariaLabelledBy: "modal-basic-title",
      size: "md",
      windowClass: "modal-class",
    });
    (document.getElementById("img01") as HTMLImageElement).src = path;
  }
}
